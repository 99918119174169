// DonateToPublicFund.js

import React, { useEffect, useState } from "react";
import Web3 from "web3";

const PUBLIC_FUND_DONATION_ADDRESS = '0xcE306098678024cBD4a998CB38d7c6ECd28a451D';

function DonateToPublicFund({ auth }) {

    const [web3, setWeb3] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [donationAmount, setDonationAmount] = useState('');
    const [donateInitiated, setDonateInitiated] = useState(false);

    useEffect(() => {
        initializeWeb3();
    }, []);

    const initializeWeb3 = async () => {
        let accounts;

        // Browser extension Ethereum provider
        if (window.ethereum) {
            const web3_instance = new Web3(window.ethereum);
            setWeb3(web3_instance);

            accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            web3_instance.eth.defaultAccount = accounts?.[0];
        }
        // No Ethereum provider detected
        else {
            // @TODO: handle case where no wallets are found
            setErrorMessage('Failed to connect to Ethereum wallet. Install a wallet in your browser to make donations.');
            return;
        }

        console.log(`Console connected to ethereum accounts: ${accounts}`);
    };


    const handleDonateToPublicFund = async (donationValue) => {
        if (!web3 || !web3.eth.defaultAccount) {
            setErrorMessage('Please connect an Ethereum wallet to your browser');
            return;
        }

        try {
            const wei_donationValue = await web3.utils.toWei(donationValue, "ether");
            const hex_donationValue = await web3.utils.numberToHex(wei_donationValue);

            const transactionHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{from: web3.eth.defaultAccount, to: PUBLIC_FUND_DONATION_ADDRESS, value: hex_donationValue}],
            });

            submitTransactionToServer(transactionHash);
            setDonationAmount('');
            setStatusMessage(`Thank you for your donation! Transaction hash: ${transactionHash}`);
            console.log(`handleDonateToFund completed transaction: ${transactionHash}`);

        } catch (err) {
            setErrorMessage(`Error while signing send donation request: ${err.message}`);
        }
    };


    const submitTransactionToServer = async (transactionHash) => {
        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        const requestBody = JSON.stringify({ transactionHash: transactionHash, fundID: 'PublicFund', userIdToken: sessionToken });

        fetch('/submit-transaction', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .catch((err) => {
                setStatusMessage(`Error occurred while submitting donation transaction to server`);
            });
    };


    return (
        <div className="donate">
            { errorMessage && <h4 className="error">{ errorMessage }</h4> }
            { statusMessage && <h4 className="status">{ statusMessage }</h4> }

            { donateInitiated ? (
                <div>
                    <input type="text" name="donationamount" placeholder="Donation Amount" value={ donationAmount } onChange={(event) => { setDonationAmount(event.target.value)}} />
                    <button onClick={() => handleDonateToPublicFund(donationAmount) }>Donate</button><br />
                    <button onClick={() => { setDonationAmount(''); setDonateInitiated(false); }}>Cancel</button><br />
                </div>
            ) : (
                <><button className="donate-button" onClick={() => setDonateInitiated(true)}>Donate to Public Fund</button><br /></>
            )}
        </div>
    );
}

export default DonateToPublicFund;

