// FundPage.js

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Funds from '../components/Funds';

const firebaseConfig = {
  apiKey: "AIzaSyBePgEZpMWBogiXpNt4fEqlguaur8Cp10A",
  authDomain: "publicfund-e2c6b.firebaseapp.com",
  projectId: "publicfund-e2c6b",
  storageBucket: "publicfund-e2c6b.appspot.com",
  messagingSenderId: "400968803374",
  appId: "1:400968803374:web:bb6abef1edf29dbc4debbf",
  measurementId: "G-G83PH4DK78"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function FundPage() {

    const navigate = useNavigate();
    const pageLocation = useLocation();

    const [errorMessage, setErrorMessage] = useState('');
    const [fundID, setFundID] = useState('');

    useEffect(() => {
        const query = new URLSearchParams(pageLocation.search);
        const queryFundID = query.get('fundid');
        setFundID(queryFundID);
    }, []);


    const handleCopyShareLink = async (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(`https://publicfund.io/fund?fundid=${fundID}`);
        alert('\n\nLink copied to clipboard!\n    Paste to share');
    };


    const handleError = (error, displayMessage, showError=true) => {
        if (error) {
            switch (error.message) {
                case 'Unauthorized':
                    // @NOTE: unauthorized request status is accepted for link sharing purposes
                    return;
                default:
                    break;
            }
        }

        if (showError) {
            setErrorMessage(displayMessage);
        }
    };


    return (
        <div>
            <h1>Public Fund</h1>
            <h2>Fund Details</h2>

            <button onClick={function () { navigate(-1); }}>Back</button>
            <button onClick={function () { navigate('/'); }}>Home</button>
            <br />
            <button onClick={function () { navigate('/signin'); }}>Sign In</button>
            <button onClick={function () { navigate('/signup'); }}>Sign Up</button>
            <br />
            <button className="asharefundbutton" onClick={(event) => handleCopyShareLink(event)}>Share Link</button>

            { errorMessage && <h4 className="error">{ errorMessage }</h4> }

            { fundID && <Funds query={{ 'type': 'fundid', 'value': fundID }} auth={null} web3={null} handleError={handleError} /> }
        </div>
    );
}

export default FundPage;

