// DonateComponent.js

import React, { useState } from 'react';
import './DonateComponent.css';

const DonateComponent = ({ fundID, donationAddress, setErrorMessage, setStatusMessage, auth, web3 }) => {

    const [step, setStep] = useState(0);
    const [selectedToken, setSelectedToken] = useState('');
    const [amount, setAmount] = useState('');
    
    const handleDonateClick = () => setStep(1);
    const handleCancel = () => setStep(0);
    // const handleTokenChange = (e) => setSelectedToken(e.target.value);
    const handleTokenSelect = (token) => setSelectedToken(token);
    const handleAmountChange = (e) => setAmount(e.target.value);
    const handleSubmit = () => {
        handleDonateToFund();
        setStep(0);
    };

    // const tokenOptions = ['ETH', 'USDT', 'USDC', 'MATIC', 'LINK', 'SHIB', 'UNI', 'DAI', 'AAVE'];
    const tokenOptions = ['ETH', 'MATIC'];

    // const tokenToChainDataMap = new Map([['ETH', 1], ['USDT', 1], ['USDC', 1], ['MATIC', 137], ['LINK', 1], ['SHIB', 27], ['UNI', 1], ['DAI', 7100], ['AAVE', 1]]); // @TODO: finish filling out correct chainId mappings
    const tokenToChainDataMap = {
                                    'ETH': {
                                        chainId: 1,
                                        chainName: "Ethereum Mainnet",
                                        rpcUrl: ["https://eth-mainnet.g.alchemy.com/v2/demo"],
                                        nativeCurrencyName: "ETH",
                                        nativeCurrencySymbol: "ETH",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'USDT': {
                                        chainId: 137,
                                        chainName: "Polygon Mainnet",
                                        rpcUrl: ["https://polygon-rpc.com"],
                                        nativeCurrencyName: "MATIC",
                                        nativeCurrencySymbol: "MATIC",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'USDC': {
                                        chainId: 137,
                                        chainName: "Polygon Mainnet",
                                        rpcUrl: ["https://polygon-rpc.com"],
                                        nativeCurrencyName: "MATIC",
                                        nativeCurrencySymbol: "MATIC",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'MATIC': {
                                        chainId: 137,
                                        chainName: "Polygon Mainnet",
                                        rpcUrl: ["https://polygon-rpc.com"],
                                        nativeCurrencyName: "MATIC",
                                        nativeCurrencySymbol: "MATIC",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'LINK': {
                                        chainId: 137,
                                        chainName: "Polygon Mainnet",
                                        rpcUrl: ["https://polygon-rpc.com"],
                                        nativeCurrencyName: "MATIC",
                                        nativeCurrencySymbol: "MATIC",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'SHIB': {
                                        chainId: 27,
                                        chainName: "ShibaChain",
                                        rpcUrl: ["https://rpc.shibchain.org"],
                                        nativeCurrencyName: "SHIB",
                                        nativeCurrencySymbol: "SHIB",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'UNI': {
                                        chainId: 137,
                                        chainName: "Polygon Mainnet",
                                        rpcUrl: ["https://polygon-rpc.com"],
                                        nativeCurrencyName: "MATIC",
                                        nativeCurrencySymbol: "MATIC",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'DAI': {
                                        chainId: 7100,
                                        chainName: "Polygon Mainnet",
                                        rpcUrl: ["https://polygon-rpc.com"],
                                        nativeCurrencyName: "MATIC",
                                        nativeCurrencySymbol: "MATIC",
                                        nativeCurrencyDecimals: 18
                                    },
                                    'AAVE': {
                                        chainId: 137,
                                        chainName: "Polygon Mainnet",
                                        rpcUrl: ["https://polygon-rpc.com"],
                                        nativeCurrencyName: "MATIC",
                                        nativeCurrencySymbol: "MATIC",
                                        nativeCurrencyDecimals: 18
                                    }
                                };


    const submitTransactionToServer = async (transactionHash) => {
        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        const requestBody = JSON.stringify({ transactionHash: transactionHash, fundID: fundID, userIdToken: sessionToken });

        fetch('/submit-transaction', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .catch((err) => {
                setErrorMessage(`Error occurred while submitting donation transaction to server`);
            });
    };


    // const handleDonateToFund = async (donationAddress, donationValue) => {
    const handleDonateToFund = async () => {
        if (!auth || !auth.currentUser) {
            setErrorMessage('Please sign in to make a donation');
            return;
        }

        if (!web3 || !web3.eth.defaultAccount) {
            setErrorMessage('Please connect an Ethereum wallet to your browser');
            return;
        }

        // const chainId = tokenToChainDataMap.get(selectedToken);
        const tokenData = tokenToChainDataMap[selectedToken];
        const chainId = tokenData['chainId'];
        const hexChainId = '0x' + chainId.toString(16);

        // Switch to correct chain
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: hexChainId }]
            });
        } catch (switchError) {
            // The chain has not been added to MetaMask wallet
            if (switchError.code === 4902) {
                try {
                  await window.ethereum.request({
                      method: "wallet_addEthereumChain",
                      params: [
                        {
                            chainId: hexChainId,
                            chainName: tokenData['chainName'],
                            rpcUrls: tokenData['rpcUrl'],
                            nativeCurrency: {
                                name: tokenData['nativeCurrencyName'],
                                symbol: tokenData['nativeCurrencySymbol'],
                                decimals: tokenData['nativeCurrencyDecimals']
                            }
                        }
                      ]
                    });
                } catch (addError) {
                    setErrorMessage(`Error adding chain to wallet: ${addError.message}`);
                    return;
                }
            }
            else {
                setErrorMessage(`Error occurred while switching chain network: ${switchError.message}`);
                return;
            }
        }

        // Send donation transaction
        try {

            // @TODO: Make value conversion compatible for all chain options
            // const wei_donationValue = await web3.utils.toWei(donationValue, "ether");
            const wei_donationValue = await web3.utils.toWei(amount, "ether");
            const hex_donationValue = await web3.utils.numberToHex(wei_donationValue);

            // @TODO: add gasEstimate to params (shows warning without gasEstimate)
            const transactionHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{from: web3.eth.defaultAccount, to: donationAddress, value: hex_donationValue}],
            });

            submitTransactionToServer(transactionHash);
            // setDonationAmount('');
            setStatusMessage(`Thank you for your donation! Transaction hash: ${transactionHash}`);
            console.log(`Completed transaction: ${transactionHash}`);

        } catch (err) {
            setErrorMessage(`Error while signing send donation request: ${err.message}`);
        }
    };


    return (
        <div className="donate-container">
            {step === 0 && (
                <button className="donate-button" onClick={handleDonateClick}>Donate</button>
            )}
            {step === 1 && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Select Cryptocurrency</h2>

                        <div className="token-grid">
                            {tokenOptions.map((token) => (
                                <div key={token} className={`token-item ${selectedToken === token ? 'selected' : ''}`} onClick={() => handleTokenSelect(token)} >
                                    <img src={`images/icon-${token}.png`} alt={token} className="token-image" />
                                    <span>{token}</span>
                                </div>
                            ))}
                        </div>

                        <button className="cancel-button" onClick={handleCancel}>Cancel</button>
                        <button className="next-button" onClick={() => setStep(2)} disabled={!selectedToken}>Next</button>
                    </div>
                </div>
            )}
            {step === 2 && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Donate {selectedToken}</h2>
                        <input type="number" className="amount-input" placeholder="Enter amount" value={amount} onChange={handleAmountChange}/>
                        <button className="cancel-button" onClick={() => setStep(1)}>Back</button>
                        <button className="submit-button" onClick={handleSubmit} disabled={!amount}>Donate</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DonateComponent;

