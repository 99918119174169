// DisplayFunds.js

import React, { useEffect, useState } from "react";
import Funds from '../components/Funds';

function DisplayFunds({ auth, web3, handleError }) {

    const fundCategories = ['Animal Care', 'Business Funding', 'Community', 'Political Campaign', 'Creative', 'Education', 'Emergencies', 'Environment', 'Events', 'Family', 'Funeral & Memorial', 'Medical', 'Monthly Bills', 'Newlyweds', 'Travel', 'Volunteer', 'Wishes', 'Other'];
    const categoryOptions = fundCategories.map((category, index) => {
        return { optionKey: "categoryOption" + index.toString(), optionValue: category };
    });

    const [fundSearchQueryType, setFundSearchQueryType] = useState('trending');
    const [fundSearchQueryValue, setFundSearchQueryValue] = useState('');
    const [selectedFundCategory, setSelectedFundCategory] = useState(fundCategories[0]);
    const [searchTitle, setSearchTitle] = useState('');

    const handleChangeSearchOption = (event, searchOption) => {
        setFundSearchQueryType(searchOption);

        const elements = document.getElementsByClassName("fundsearchoption");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("selected");
            elements[i].classList.add("unselected");
        }

        event.target.classList.remove("unselected");
        event.target.classList.add("selected");
    };


    return (
        <div className="outerfundscontainer" id="outerfundscontainer">
            <h2>Active Fundraisers</h2>

            <div className="fundscontainer">
                <button className="fundsearchoption selected" onClick={(event) => handleChangeSearchOption(event, "trending")}>Trending</button>
                <button className="fundsearchoption unselected" onClick={(event) => handleChangeSearchOption(event, "recent")}>Recently Posted</button>
                <button className="fundsearchoption unselected" onClick={(event) => handleChangeSearchOption(event, "mostraised")}>Top Fundraisers</button>
                <button className="fundsearchoption unselected" onClick={(event) => handleChangeSearchOption(event, "needslove")}>Needs Love</button>

                <select className="fundsearchoption unselected" name="fundcategoryselection" value={selectedFundCategory} onChange={(event) => { setSelectedFundCategory(event.target.value); handleChangeSearchOption(event, "category"); setFundSearchQueryValue(event.target.value); }}>
                    { categoryOptions.map((option) => (
                        <option key={option.optionKey} value={option.optionValue}>{ option.optionValue }</option>
                    ))}
                </select>

                <br />
                <input type="text" name="searchtitle" placeholder="Search a Fundraiser" value={searchTitle} onChange={(event) => { setSearchTitle(event.target.value) }} />
                <button className="fundsearchoption unselected" onClick={(event) => { handleChangeSearchOption(event, "title"); setFundSearchQueryValue(searchTitle); }}>Search</button>

                <Funds key={`${fundSearchQueryType}-${fundSearchQueryValue}`} query={{ type: fundSearchQueryType, value: fundSearchQueryValue }} auth={auth} web3={web3} handleError={handleError} />
            </div>
        </div>
    );
}

export default DisplayFunds;

