// ContactPage.js

import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';

function ContactPage() {

    const navigate = useNavigate();

    const [contactName, setContactName] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [supportRequestMessage, setSupportRequestMessage] = useState('');
    const [supportRequestStatusMessage, setSupportRequestStatusMessage] = useState('');

    const handleSubmitContactRequest = async (event) => {
        event.preventDefault();

        if (!contactName || !emailValue || !supportRequestMessage) {
            setSupportRequestStatusMessage(`Please fill out all fields`);
            return;
        }

        const requestBody = JSON.stringify({ name: contactName, email: emailValue, message: supportRequestMessage });

        fetch('/contact', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .then(() => {
                            setEmailValue('');
                            setSupportRequestMessage('');
                            setSupportRequestStatusMessage('Your support request has been submitted. Our team will get back to you ASAP');
                            setTimeout(() => {
                                setSupportRequestStatusMessage('');
                          }, 4000);
                 })
            .catch( setSupportRequestStatusMessage(`Error occurred while submitting support request`) );
    };


  return (
    <>
    <NavBar />
    <div className="contact-page">

        <div className="pfheader"  onClick={() => navigate("/")} >
            <h1>Public Fund</h1>
        </div>

        <h2>Contact Us</h2>

        { supportRequestStatusMessage && <h4 className="status">{ supportRequestStatusMessage }</h4> }

        <form onSubmit={handleSubmitContactRequest}>
            <h5>Name</h5>
            <input type="text" name="contactName" placeholder="Name" value={contactName} onChange={(event) => { setContactName(event.target.value) }} />

            <h5>Email</h5>
            <input type="email" name="email" placeholder="Email" value={emailValue} onChange={(event) => { setEmailValue(event.target.value) }} />

            <h5>How can we help you?</h5>
            <textarea placeholder="Describe your situation" value={supportRequestMessage} cols="32" rows="4" onChange={(event) => { setSupportRequestMessage(event.target.value) }} /><br />
            <button type="submit">Submit</button><br />
        </form>

        <br />
        <br />
        <br />
        <br />

        <div>
            <h3><a href="mailto:cole@publicfund.io">cole@publicfund.io</a></h3>
        </div>
    </div>
    </>
  );
}

export default ContactPage;

