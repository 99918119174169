// Fund.js

import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import DonateComponent from './DonateComponent';

function Fund({ fund, auth, web3, handleError }) {

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [donationAmount, setDonationAmount] = useState('');
    const [donateInitiated, setDonateInitiated] = useState(false);
    const [postUpdateInitiated, setPostUpdateInitiated] = useState(false);
    const [showUpdates, setShowUpdates] = useState(false);
    const [editInitiated, setEditInitiated] = useState(false);
    const [editFundFormData, setEditFundFormData] = useState({
        update: '',
        title: fund.title,
        story: fund.story,
        fundID: fund.fundID
    });


    const handleCopyShareLink = async (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(`https://publicfund.io/fund?fundid=${fund.fid}`);
        alert('\n\nLink copied to clipboard!\n    Paste to share');
    };


    const handleSubmitEditFund = async (event) => {
        if (event) {
            event.preventDefault();
        }

        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        const requestBody = JSON.stringify({ fundDetails: editFundFormData, userIdToken: sessionToken });

        fetch('/editfund', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .then(() => {
                setEditInitiated(false);
                setPostUpdateInitiated(false);
            })
            .catch((err) => {
                // handleError(null, `Error occurred while saving edits`);
                setErrorMessage(`Error occurred while saving edits`);
            });
    };


    if (editInitiated) {
        return (
            <div className="fund editfund">
                <form id="editfundform" onSubmit={ handleSubmitEditFund }>
                    <h3>Fundraiser Title</h3>
                    <input type="text" name="title" placeholder="Fundraiser Title" value={ editFundFormData.title } onChange={(event) => { setEditFundFormData({ ...editFundFormData, title: event.target.value })}} />

                    <h4>Fundraiser Story</h4>
                    <textarea value={ editFundFormData.story } cols="32" rows="4" onChange={(event) => { setEditFundFormData({ ...editFundFormData, story: event.target.value })}} /><br />

                    <button type="submit">Submit</button>
                </form>

                <button onClick={() => setEditInitiated(false)}>Cancel Edit</button>
            </div>
        );
    }

    //            <Link to={ `/fund?fundid=${fund.fid}` }>Fund Link</Link>
    // @TODO: change line 202 input type to number?
    /*
                    { donateInitiated ? (
                        <div className="fund-donation">
                            <input type="text" name="donationamount" placeholder="Donation Amount" value={ donationAmount } onChange={(event) => { setDonationAmount(event.target.value)}} />
                            <button onClick={() => handleDonateToFund(fund.address, donationAmount)}>Submit Donation</button><br />
                            <button onClick={() => { setDonationAmount(''); setDonateInitiated(false); }}>Cancel</button>
                        </div>
                    ) : (
                        <><button onClick={() => setDonateInitiated(true)}>Donate</button></>
                    )}
     */

    //            <p>Total amount raised (ETH): { fund.totalDonations / 10e18 }</p>

    return (
        <div className="fund" onClick={() => navigate(`/fund?fundid=${fund.fid}`)}>
            { errorMessage && <div className="error"><h4>{ errorMessage }</h4></div> }
            { statusMessage && <div className="status"><h4>{ statusMessage }</h4></div> }

            <div className="fund-details">
                <button className="sharefundbutton" onClick={(event) => handleCopyShareLink(event)}>Share Link</button>
                <h3>{ fund.title }</h3>
                <h4>Category: { fund.category }</h4>
                <h5>Recipient: { fund.recipientName }</h5>
                <img src={ fund.rawimage } width="250px" />
                <p>{ fund.story }</p>
            </div>

            { showUpdates ? (
                <div className="fund-updates">
                    <ol>
                        { fund.updates && ( fund.updates.map((update, i) => (
                            <li key={ `update-${i}-${fund.fid}` }>
                                <p>{ update }</p>
                            </li>
                        )))}
                    </ol>

                    <button onClick={(event) => { event.stopPropagation(); setShowUpdates(false); }}>Hide Updates</button>
                </div>
            ) : (
                <><button onClick={(event) => { event.stopPropagation(); setShowUpdates(true); }}>Show Updates</button></>
            )}

            { fund.editable ? (
                <div className="fund-owner-panel" onClick={(event) => event.stopPropagation()} >
                    <button onClick={() => setEditInitiated(true)}>Edit Details</button><br />

                    { postUpdateInitiated ? (
                        <div className="fund-add-update">
                            <h2>Update</h2>
                            <textarea placeholder="Update..." value={ editFundFormData.update } cols="32" rows="4" onChange={(event) => { setEditFundFormData({ ...editFundFormData, update: event.target.value })}} />
                            <br />
                            <button onClick={() => handleSubmitEditFund(null) }>Submit</button>
                            <br />
                            <button onClick={() => setPostUpdateInitiated(false)}>Cancel</button>
                        </div>
                    ) : (
                        <><button onClick={() => setPostUpdateInitiated(true)}>Post an Update</button></>
                    )}
                </div>
            ) : (
                <div className="fund-donation-container" onClick={(event) => event.stopPropagation()} >
                    <DonateComponent fundID={fund.fundID} donationAddress={fund.address} setErrorMessage={setErrorMessage} setStatusMessage={setStatusMessage} auth={auth} web3={web3} />
                </div>
            )}
        </div>
    );
}

export default Fund;

