// App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import DonatePage from './pages/DonatePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ConsolePage from './pages/ConsolePage';
import FundPage from './pages/FundPage';

function App() {

  useEffect(() => {
    document.title = 'Public Fund';
  }, []);

  return (
    <Router>
        <Routes>
            <Route exact path="/" element={ <LandingPage /> } />
            <Route path="/about" element={ <AboutPage /> } />
            <Route path="/contact" element={ <ContactPage /> } />
            <Route path="/donatepage" element={ <DonatePage /> } />
            <Route path="/signin" element={ <SignInPage /> } />
            <Route path="/signup" element={ <SignUpPage /> } />
            <Route path="/console" element={ <ConsolePage /> } />
            <Route path="/fund" element={ <FundPage /> } />
        </Routes>
    </Router>
  );
}

export default App;

