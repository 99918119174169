// CompletedDonations.js

import React, { useEffect, useState } from "react";
import Funds from '../components/Funds';

function CompletedDonations({ setViewDonationsInitiated, auth, web3, handleError }) {

    const [fundIDs, setFundIDs] = useState([]);
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        retrieveCompletedDonations();
    }, []);

    const retrieveCompletedDonations = async () => {
        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        const requestBody = JSON.stringify({ userIdToken: sessionToken });

        fetch('/donations', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Unauthorized');
                    }
                    else {
                        throw new Error('Error');
                    }
                }
                else {
                    return response.json();
                }
            })
            .then((data) => setFundIDs(data.donations))
            .catch((error) => handleError(error, `Error occurred while retrieving funds`, false));
    };


    return (
        <div>
            <h2>Completed Donations</h2>
            <button onClick={() => setViewDonationsInitiated(false) }>Close</button>

            <ol>
                { fundIDs && ( fundIDs.map((fundID) => (
                    <li key={ fundID }>
                        <Funds query={{ 'type': 'fundid', 'value': fundID }} auth={auth} web3={web3} handleError={handleError} />
                    </li>
                )))}
            </ol>

            <button onClick={() => setViewDonationsInitiated(false) }>Close</button>
        </div>
    );
}

export default CompletedDonations;

