// ConsolePage.js

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import Web3 from "web3";

import CompletedDonations from '../components/CompletedDonations';
import StartFund from '../components/StartFund';
import Funds from '../components/Funds';
import DisplayFunds from '../components/DisplayFunds';
import DonateToPublicFund from '../components/DonateToPublicFund';

const DEBUG = false;

const firebaseConfig = {
  apiKey: "AIzaSyBePgEZpMWBogiXpNt4fEqlguaur8Cp10A",
  authDomain: "publicfund-e2c6b.firebaseapp.com",
  projectId: "publicfund-e2c6b",
  storageBucket: "publicfund-e2c6b.appspot.com",
  messagingSenderId: "400968803374",
  appId: "1:400968803374:web:bb6abef1edf29dbc4debbf",
  measurementId: "G-G83PH4DK78"
};


function ConsolePage() {

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth(app);
    const navigate = useNavigate();

    const [web3, setWeb3] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [viewDonationsInitiated, setViewDonationsInitiated] = useState(false);
    const [startFundInitiated, setStartFundInitiated] = useState(false);
    const [viewMyFunds, setViewMyFunds] = useState(false);
    const [supportRequestInitiated, setSupportRequestInitiated] = useState(false);
    const [emailValue, setEmailValue] = useState('');
    const [supportRequestMessage, setSupportRequestMessage] = useState('');
    const [supportRequestStatusMessage, setSupportRequestStatusMessage] = useState('');

    useEffect(() => {
        initializeWeb3();
    }, []);

    const initializeWeb3 = async () => {
        let accounts;

        // Locally hosted Ethereum provider
        if (DEBUG) {
            const web3_instance = new Web3("http://127.0.0.1:8545");
            setWeb3(web3_instance);

            accounts = await web3_instance.eth.getAccounts();
            web3_instance.eth.defaultAccount = accounts?.[0];

            // await web3_instance.eth.personal.unlockAccount(accounts?.[0], '', 600);
        }
        // Browser extension Ethereum provider
        else if (window.ethereum) {
            const web3_instance = new Web3(window.ethereum);
            setWeb3(web3_instance);

            accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            web3_instance.eth.defaultAccount = accounts?.[0];
        }
        // No Ethereum provider detected
        else {
            // @TODO: handle case where no wallets are found
            // setErrorMessage('Failed to connect to Ethereum wallet. Install a wallet in your browser to make donations.');
            return;
        }

        // console.log(`Console connected to ethereum account`);
        console.log(`Console connected to ethereum accounts: ${accounts}`);
    };


    const handleSignOut = async () => {
        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        signOut(auth).then(() => {
            // Signout successful
        }).catch( (error) => {
            // An error occurred
        }).finally( () => {
            // Deactivate session cookies
            const requestBody = JSON.stringify({ userIdToken: sessionToken });

            fetch('/signout', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: requestBody
                })
                .then( navigate('/') )
                .catch( navigate('/') );
        });
    };


    const handleError = (error, displayMessage, displayError=true) => {
        if (error) {
            switch (error.message) {
                case 'Unauthorized':
                    navigate('/signin');
                    return;
                default:
                    break;
            }
        }

        if (displayError) {
            setErrorMessage(displayMessage);
        }
    };


    const handleSubmitSupportRequest = async (event) => {
        event.preventDefault();

        if (!supportRequestMessage || !emailValue) {
            setSupportRequestStatusMessage(`Please fill out all fields`);
            return;
        }

        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        const requestBody = JSON.stringify({ message: supportRequestMessage, email: emailValue, userIdToken: sessionToken });

        fetch('/support', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .then(() => {
                            setEmailValue('');
                            setSupportRequestMessage('');
                            setSupportRequestStatusMessage('Your support request has been submitted. Our team will get back to you ASAP');
                            setTimeout(() => {
                                setSupportRequestInitiated(false);
                                setSupportRequestStatusMessage('');
                          }, 4000);
                 })
            .catch( setSupportRequestStatusMessage(`Error occurred while submitting support request`) );
    };


    if (viewDonationsInitiated) {
        return (
            <CompletedDonations setViewDonationsInitiated={setViewDonationsInitiated} auth={auth} web3={web3} handleError={handleError} />
        );
    }

    if (startFundInitiated) {
        return (
            <StartFund setStartFundInitiated={setStartFundInitiated} auth={auth} />
        );
    }

    if (supportRequestInitiated) {
        return (
            <div>
                <h2>Support Request</h2>

                { supportRequestStatusMessage && <h4 className="status">{ supportRequestStatusMessage }</h4> }

                <form onSubmit={handleSubmitSupportRequest}>
                    <h5>Enter your email address</h5>
                    <input type="email" name="email" placeholder="Email" value={emailValue} onChange={(event) => { setEmailValue(event.target.value) }} />

                    <h5>Please describe what we can help you with</h5>
                    <textarea placeholder="Describe your issue" value={supportRequestMessage} cols="32" rows="4" onChange={(event) => { setSupportRequestMessage(event.target.value) }} /><br />
                    <button type="submit">Submit</button><br />
                </form>

                <button onClick={() => setSupportRequestInitiated(false) }>Cancel</button>
            </div>
        );
    }

    if (viewMyFunds) {
        // const userID = auth.currentUser.uid;
        //        <Funds query={{ type: "owner", value: userID }} auth={auth} web3={web3} handleError={handleError} />

        return (
            <div className="fundscontainer">
                <h2>Your Fundraisers</h2>
                <button onClick={() => setViewMyFunds(false) }>Close</button>
                <Funds query={{ type: "owner", value: "self" }} auth={auth} web3={web3} handleError={handleError} />
                <button onClick={() => setViewMyFunds(false) }>Close</button>
            </div>
        );
    }

    //            { startFundInitiated ? <StartFund setStartFundInitiated={setStartFundInitiated} /> : <button onClick={() => setStartFundInitiated(true) }>Start a Fund</button> }
    //            <button onClick={ handleGetBalance }>Get Balance</button><br />
    //            { viewDonationsInitiated ? <CompletedDonations setViewDonationsInitiated={setViewDonationsInitiated} web3={web3} handleError={handleError} /> : <button onClick={() => setViewDonationsInitiated(true) }>My Donations</button> }
    return (
        <div className="console">
            <div className="mobile-title"><h2>Public Fund</h2></div>

            <div className="console-top">
                <h1>Public Fund</h1>

                <button onClick={() => setViewDonationsInitiated(true) }>My Donations</button>

                <button onClick={() => setStartFundInitiated(true) }>Start a Fund</button>

                <button onClick={() => setViewMyFunds(true) }>View My Funds</button>

                <button onClick={() => setSupportRequestInitiated(true) }>Support</button>

                <button onClick={ handleSignOut }>Sign Out</button>
            </div>

            <div className="spacing"></div>

            { errorMessage && <h4 className="error">{ errorMessage }</h4> }

            <DonateToPublicFund auth={auth} />

            <DisplayFunds auth={auth} web3={web3} handleError={handleError} />
        </div>
    );
}

export default ConsolePage;

