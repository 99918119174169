// NavBar.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import './../App.css';

const NavBar = () => {

    const toggleMobileMenu = (event) => {
        event.target.classList.toggle("open");
    };

    /*
        <nav className="navbar">
            <h4>Public Fund</h4>
            <div className="navbar-links">
                <Link to="/" className="nav-link">Home</Link>
                <Link to="/about" className="nav-link">About</Link>
                <Link to="/contact" className="nav-link">Contact</Link>
                <Link to="/signin" className="nav-link">Sign In</Link>
                <Link to="/signup" className="nav-link">Sign Up</Link>
            </div>
        </nav>
     */
    return (
        <>
        <div className="header">
            <a id="login-button" href="/signin">Sign In</a>
            <div id="brand"><a href="/">Public Fund</a></div>

            <nav>
                <ul>
                    <li><a className="navoption" href="/">Home <span className="downarrowsymbol"><FontAwesomeIcon icon={faArrowDown} /></span></a></li>
                    <li><a className="navoption" href="/about">About <span className="downarrowsymbol"><FontAwesomeIcon icon={faArrowDown} /></span></a></li>
                    <li><a className="navoption" href="/contact">Contact <span className="downarrowsymbol"><FontAwesomeIcon icon={faArrowDown} /></span></a></li>
                    <li id="login"><a href="/signin" >Sign In</a></li>
                    <li id="signup"><a href="/signup">Sign Up</a></li>
                </ul>
            </nav>

            <div id="hamburger-icon" onClick={(event) => toggleMobileMenu(event)}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>

                <ul className="mobile-menu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact</a></li>
                    <br />
                    <li id="login"><a href="/signin" >Login</a></li>
                    <li id="signup"><a href="/signup">Sign Up</a></li>
                </ul>
            </div>
        </div>
        </>
    );
};

export default NavBar;

