// LandingPage.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import '../FeaturesSectionCSS.css';
import NavBar from '../components/NavBar';
import DisplayFunds from '../components/DisplayFunds';

const firebaseConfig = {
  apiKey: "AIzaSyBePgEZpMWBogiXpNt4fEqlguaur8Cp10A",
  authDomain: "publicfund-e2c6b.firebaseapp.com",
  projectId: "publicfund-e2c6b",
  storageBucket: "publicfund-e2c6b.appspot.com",
  messagingSenderId: "400968803374",
  appId: "1:400968803374:web:bb6abef1edf29dbc4debbf",
  measurementId: "G-G83PH4DK78"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function LandingPage() {

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const handleError = (error, displayMessage) => {
        if (error) {
            switch (error.message) {
                case 'Unauthorized':
                    // @NOTE: unauthorized request status is expected on landing page
                    return;
                default:
                    break;
            }
        }

        setErrorMessage(displayMessage);
    };


    return (
        <div className="pf-main">
            <NavBar />

            <div className="hero">
                <div className="hero-content">
                    <div className="pfheader">
                        <h1>Public Fund</h1>
                    </div>

                    <h4>Decentralized Crowdfunding</h4>
                    <h2>Go fund yourself</h2>
                    <button className="landingpagebutton" onClick={function () { navigate('/signup'); }}>Start a Fund</button><br />
                </div>
            </div>

            <section className="features-section">
              <h2 className="smaller-h2">Key Features</h2>

              <div className="features-container">
                  <div className="feature-card">
                      <img src="/images/low-fees.webp" alt="Low Fees" className="feature-image" />
                      <h3>Low Fees</h3>
                      <p>Public Fund leverages blockchain transactions to reduce fees and leave more money in the hands of the people.</p>
                  </div>

                  <div className="feature-card">
                      <img src="/images/transparent5.webp" alt="Transparent" className="feature-image" />
                      <h3>Transparent</h3>
                      <p>Every donation is recorded on immutable and publicly accessible blockchain ledgers.</p>
                  </div>

                  <div className="feature-card">
                      <img src="/images/decentralized4.webp" alt="Decentralized" className="feature-image" />
                      <h3>Decentralized</h3>
                      <p>Access funds immediately with no middleman between you and the donor. P2P payments ensure quick, hassle-free transactions.</p>
                  </div>
              </div>
            </section>

            <div className="outer-card-container">
                <h4>Modern Fundraising Starts Here.</h4>
                <button className="landingpagebutton" onClick={function () { navigate('/signup'); }}>Sign Up</button>
                <br />
                <button className="landingpagebutton altbuttoncolor" onClick={function () { navigate('/about'); }}>Learn More</button>
            </div>

            <div className="horizontal-bar"></div>

            { errorMessage && <h4 className="error">{ errorMessage }</h4> }

            <DisplayFunds auth={null} web3={null} handleError={handleError} />

            <br />
            <Link to="/donatepage" className="donate-link">Donate To Public Fund</Link>
        </div>
    );
}

export default LandingPage;

