// DonatePage.js

import React from 'react';
import NavBar from '../components/NavBar';
import DonateToPublicFund from '../components/DonateToPublicFund';

function DonatePage() {

  return (
    <>
    <NavBar />
    <div className="about-page">

        <h1>Public Fund</h1>

        <h2>Donations</h2>
        <h4>We do not charge anything to use our platform, but we always welcome donations.</h4>
        <br />

        <DonateToPublicFund auth={null} />
    </div>
    </>
  );
}

export default DonatePage;

