// StartFund.js

import React, { useEffect, useState } from "react";

function StartFund({ setStartFundInitiated, auth }) {

    const fundCategories = ['Animal Care', 'Business Funding', 'Community', 'Political Campaign', 'Creative', 'Education', 'Emergencies', 'Environment', 'Events', 'Family', 'Funeral & Memorial', 'Medical', 'Monthly Bills', 'Newlyweds', 'Travel', 'Volunteer', 'Wishes', 'Other'];

    const [statusMessage, setStatusMessage] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [imageToDisplay, setImageToDisplay] = useState(null);
    const [formData, setFormData] = useState({
        category: '',
        recipientType: '',
        recipientName: '',
        coverImage: null,
        title: '',
        story: '',
        address: ''
    });


    const handleChangeCategory = (event) => {
        // event.preventDefault(); // @NOTE: do not prevent default event
        setFormData({ ...formData, category: event.target.value });
    };


    const handleChangeRecipientType = (event) => {
        setFormData({ ...formData, recipientType: event.target.value });
    };


    const convertImageToBlob = (event) => {
        setImageToDisplay(event.target.files[0]);

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function() {
            const imageToAttach = reader.result;
            setFormData({ ...formData, coverImage: imageToAttach });
        }
    };


    const handleSubmitStartFund = async (event) => {
        event.preventDefault();

        if (!formData.category || !formData.recipientType || !formData.recipientName || !formData.title || !formData.story || !formData.address || !formData.coverImage) {
            setStatusMessage(`Please fill out all fields before submitting fundraiser`);
            return;
        }

        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        const requestBody = JSON.stringify({ fundDetails: formData, userIdToken: sessionToken });

        fetch('/startfund', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .then(() => { setStatusMessage('Congratulations! Your fund has been submitted.');
                          setTimeout(() => {
                              setStartFundInitiated(false);
                          }, 4000);
                 })
            .catch( setStatusMessage(`Error occurred while submitting fund to server`) );
    };


    //                <span className="categorySpan" key={"categoryinputspan" + index.toString()}><input type="radio" name="fundcategory" key={"categoryinput" + index.toString()} value={category} checked={formData['category'] === category} onChange={handleChangeCategory} />{ category }</span>
    return (
        <div className="startfund-container">
            { statusMessage && <h4 className="status">{ statusMessage }</h4> }

            <h2>Start a Fundraiser</h2>

            <form id="fundform" onSubmit={ handleSubmitStartFund }>
                <h4>Which category best describes your cause?</h4>
                <span className="categorySpan">
                    <select name="fundcategory" value={formData['category']} onChange={handleChangeCategory}>
                        { fundCategories.map((category, index) => (
                            <option key={"categoryinput" + index.toString()} value={category}>{ category }</option>
                        ))}
                    </select>
                </span>

                <h4>Who are you fundraising for?</h4>
                <input type="radio" name="fundsrecipient" value="Yourself" checked={ formData['recipientType'] === "Yourself" } onChange={ handleChangeRecipientType } /> Yourself
                <input type="radio" name="fundsrecipient" value="Someone Else" checked={ formData['recipientType'] === "Someone Else" } onChange={ handleChangeRecipientType } /> Someone Else
                <br />
                { (formData['recipientType'] === "Yourself" || formData['recipientType'] === "Someone Else") && (
                    <input type="text" name="Name" placeholder="Name" value={ formData['recipientName'] } onChange={(event) => { setFormData({ ...formData, recipientName: event.target.value })}} />
                )}

                <h4>Upload a cover photo for your fundraiser</h4>
                <input type="file" name="coverImage" accept="image/jpeg, image/jpg/, image/png" onChange={(event) => { convertImageToBlob(event) }} />
                { imageToDisplay && (
                    <div>
                        <img alt="Not found" width={"250px"} src={ URL.createObjectURL(imageToDisplay) } />
                        <br />
                        <button onClick={() => { setImageToDisplay(null) }}>Remove</button>
                    </div>
                )}

                <h4>Give your fundraiser a title</h4>
                <input type="text" name="title" placeholder="Fundraiser Title" value={ formData['title'] } onChange={(event) => { setFormData({ ...formData, title: event.target.value })}} />

                <h4>Tell us your story</h4>
                <textarea placeholder="Tell your story..." value={ formData['story'] } cols="32" rows="4" onChange={(event) => { setFormData({ ...formData, story: event.target.value })}} />

                <h4>Paste your Ethereum wallet address below</h4>
                <h5>All donations will be sent to this address. Please make sure it is correct.</h5>
                <input type="text" name="address" placeholder="Ethereum wallet address..." value={ formData['address'] } onChange={(event) => { setFormData({ ...formData, address: event.target.value })}} /><br />

                <button type="submit">Submit</button>
            </form>

            <button onClick={() => { setFormData({ ...formData, category: '', recipientType: '', recipientName: '', title: '', story: '', address: '', coverImage: null }); setStartFundInitiated(false); }}>Cancel</button>

        </div>
    );
}

export default StartFund;

