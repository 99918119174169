// AboutPage.js

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import DonateToPublicFund from '../components/DonateToPublicFund';

function AboutPage() {

  const navigate = useNavigate();

  return (
    <>
    <NavBar />
    <div className="about-page">

        <div className="pfheader"  onClick={() => navigate("/")} >
            <h1>Public Fund</h1>
        </div>

        <h2>About</h2>
        <p>Public Fund is a decentralized fundraising platform designed to revolutionize crowdfunding. By leveraging blockchain transactions we reduce fees, streamline payments, and maximize funds raised, putting more money in the hands of those who deserve it most.</p>

        <br />
        <img src="/images/feechart.png" className="mobile-graphic" alt="Not found" width="500px" />
        <h3>What's the difference?</h3>
        <p>Traditional fundraising platforms charge a 2.90% transaction fee on every donation! Public Fund leverages blockchain transactions and Web3 to make P2P payments directly from doners to fundraisers.</p>

        <h4>Do transactions still have gas fees?</h4>
        <p>Yes, gas fees are inevitable when executing blockchain transactions. The good news is gas fees are significantly lower than credit card processing fees. Total gas fees on a donation average about <i>0.0043%*</i></p>
        <br />
        <p className="asterisk-font">*Based on a donation of <i>$10,000</i>.</p>

        <h3>Donations</h3>
        <p>While our services are complimentary to all with a good cause, we do accept donations at ETH address</p>
        <h5>0xcE306098678024cBD4a998CB38d7c6ECd28a451D</h5>
        <br />
        <br />

        <div className="mobile-hidden">
            <DonateToPublicFund auth={null} />
        </div>
    </div>
    </>
  );
}

export default AboutPage;

