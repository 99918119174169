// Funds.js

import React, { useEffect, useState } from "react";
import Fund from '../components/Fund';

function Funds({ query, auth, web3, handleError }) {

    const [loading, setLoading] = useState(true);
    const [funds, setFunds] = useState([]);
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        retrieveFunds(query);
    }, []);


    const retrieveFunds = async (fundQuery) => {
        let sessionToken = '';
        try {
            const user = auth.currentUser;
            if (user) {
                sessionToken = await user.getIdToken();
            }
        } catch (error) {}
        /*
        const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        if (cookieTokens) sessionToken = cookieTokens.pop();
         */

        const requestBody = JSON.stringify({ query: fundQuery, userIdToken: sessionToken });

        fetch('/funds', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Unauthorized');
                    }
                    else {
                        throw new Error('Error');
                    }
                }
                else {
                    return response.json();
                }
            })
            .then((data) => {
                setFunds(data.funds)
                setLoading(false);
                // scrollToDisplayFunds();
            })
            .catch((error) => handleError(error, `Error occurred while retrieving funds`, false));
    };


    const scrollToDisplayFunds = () => {
        const element = document.getElementById("outerfundscontainer");
        const elementCoordinates = element.getBoundingClientRect();

        window.scrollTo({
            top: elementCoordinates.top - 20,
            behavior: "smooth"
        });
    };


    if (loading) {
        return (
            <div className="loading-container">
                <div className="wave-container">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>

                <h2>Loading...</h2>
            </div>
        );
    }

    return (
        <div className="funds">
            <ol>
                { funds.map((fund) => (
                    <li key={ fund.fid }>
                        <Fund fund={fund} auth={auth} web3={web3} handleError={handleError} />
                    </li>
                ))}
            </ol>
        </div>
    );
}

export default Funds;

