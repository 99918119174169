// SignInPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Buffer } from 'buffer';
import Web3 from "web3";
import NavBar from '../components/NavBar';
import './../signinup.css';

const DEBUG = false;
const APP_SIGN_MESSAGE = 'Public Fund sign with Ethereum message to sign';

const firebaseConfig = {
  apiKey: "AIzaSyBePgEZpMWBogiXpNt4fEqlguaur8Cp10A",
  authDomain: "publicfund-e2c6b.firebaseapp.com",
  projectId: "publicfund-e2c6b",
  storageBucket: "publicfund-e2c6b.appspot.com",
  messagingSenderId: "400968803374",
  appId: "1:400968803374:web:bb6abef1edf29dbc4debbf",
  measurementId: "G-G83PH4DK78"
};


function SignInPage() {

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth(app);
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [signInWithEmailInitiated, setSignInWithEmailInitiated] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });


    // Get JWT associated with user's firebase session
    const handleAuthFB = async (user) => {
        const sessionToken = await user.getIdToken();
        const requestBody = JSON.stringify({ fbatoken: sessionToken });

        const response = await fetch('/authfb', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            });

        if (response.status == 200) {
            const sessionCookie = await response.json();
            console.log(`signIn sessionCookie: ${sessionCookie.sessionToken}`);
            document.cookie = "public_fund_jwt=" + sessionCookie.sessionToken + "; Secure";
            navigate('/console');
        }
        else {
            setErrorMessage(`Error occurred while authorizing login`);
            setFormData({ ...formData, email: '', password: '' });
        }
    };


    const handleSignInWithEmail = async (event) => {
        event.preventDefault();

        signInWithEmailAndPassword(auth, formData['email'], formData['password'])
            .then( (userCredential) => {
                // Set Firebase auth token when user signs up with email (JWT is already set in browser cookie for ethereum sign up)
                const user = userCredential.user;
                if (user) {
                    // handleAuthFB(user);
                    // const sessionToken = await user.getIdToken();
                    // document.cookie = "public_fund_jwt=" + sessionToken + "; Secure";
                    navigate('/console');
                }
            })
            .catch((error) => {
                setErrorMessage(`Error occurred during sign in`);
                setFormData({ ...formData, email: '', password: '' });
            });
    };


    const handleSignInWithEthereum = async () => {
        let web3;
        let accounts = [];

        // Locally hosted Ethereum provider
        if (DEBUG) {
            web3 = new Web3("http://127.0.0.1:8545");
            accounts = await web3.eth.getAccounts();
            window.defaultAccount = accounts?.[0];
            // window.web3.eth.defaultAccount = accounts?.[0];
        }
        // Browser extension Ethereum provider
        else if (window.ethereum) {
            web3 = new Web3(window.ethereum);
            accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.defaultAccount = accounts?.[0];
            // window.web3.eth.defaultAccount = accounts?.[0];
        }
        // No Ethereum provider detected
        else {
            // @TODO: handle case where no wallets are found
            setErrorMessage('Failed to connect to Ethereum wallet. Install a wallet in your browser to sign in with wallet.');
            return;
        }

        console.log(`Connected to ethereum with accounts: ${accounts}`);

        // Send signed message to server and retreive JWT
        try {
            const requestBody = JSON.stringify({ uid: window.defaultAccount });

            const response = await fetch('/signin-with-ethereum', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: requestBody
                });

            if (response.status == 200) {
                const data = await response.json();
                const nonce = data['nonce'];

                const msg = `0x${Buffer.from(APP_SIGN_MESSAGE + nonce, 'utf8').toString('hex')}`;
                let uniqueSignature = '';

                // Locally hosted Ethereum provider
                if (DEBUG) {
                    try {
                        // uniqueSignature = await window.web3.eth.sign(msg, window.defaultAccount);
                        uniqueSignature = await web3.eth.sign(msg, window.defaultAccount);
                    } catch (err) {
                        setErrorMessage(`Error while signing ethereum request: ${err.message}`);
                    }
                }
                // Browser extension Ethereum provider
                else if (window.ethereum) {
                    // window.web3 = new Web3(window.ethereum);
                    // accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    // window.defaultAccount = accounts?.[0];

                    try {
                        uniqueSignature = await window.ethereum.request({
                            method: 'personal_sign',
                            params: [msg, window.defaultAccount],
                        });
                    } catch (err) {
                        setErrorMessage(`Error while signing ethereum request: ${err.message}`);
                    }
                }
                // No Ethereum provider detected
                else {
                    // @TODO: handle case where no wallets are found
                    setErrorMessage('Failed to connect to Ethereum wallet');
                    return;
                }

                const requestBody = JSON.stringify({ uid: window.defaultAccount, signature: uniqueSignature });

                const signInResponse = await fetch('/eth-unique-sign', {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: requestBody
                    });

                if (signInResponse.status == 200) {
                    console.log('Successful sign in with Ethereum');
                    const sessionCookie = await signInResponse.json();
                    // document.cookie = "public_fund_jwt=" + sessionCookie.sessionToken + "; Secure";
                    // auth.signInWithCustomToken(sessionCookie.sessionToken).then((userCredential) => {
                    signInWithCustomToken(auth, sessionCookie.sessionToken).then((userCredential) => {
                        navigate('/console');
                    })
                    .catch((error) => {
                        setErrorMessage(`Error: ct sign in failed`);
                    });
                }
                else {
                    setErrorMessage(`Error: sign in failed`);
                }
            }
            else {
                setErrorMessage(`Error: sign in failed. Make sure your wallet is connected to the correct account.`);
            }
        } catch (error) {
            setErrorMessage(`Error occurred while sending signature to server ${error}`);
        }
    };


    const handleForgotPassword = async () => {
        if (!formData['email']) {
            setErrorMessage(`Please enter your email.`);
            return;
        }

        sendPasswordResetEmail(auth, formData['email'])
            .then(() => {
                setErrorMessage(`Password reset link has been sent. Please check your email.`);
            })
            .catch((error) => {
                setErrorMessage(`Error occurred while resetting password.`);
            });
    };


    return (
        <div className="outer-sign-container">
        <NavBar />
        <br />

        <div className="sign-container">

            <h1>Public Fund</h1>
            <h2>Sign In</h2>

            { errorMessage && <h4 className="error">{ errorMessage }</h4> }
            <br />

            { !signInWithEmailInitiated && (
                <>
                <button onClick={ () => setSignInWithEmailInitiated(true) } className="sign-button">Sign In with Email</button>
                <button onClick={ handleSignInWithEthereum } className="sign-button">Sign In with Wallet</button>
                </>
            )}

            { signInWithEmailInitiated && (
                <>
                <form onSubmit={ handleSignInWithEmail }>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                    />
                    <br />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={(event) => setFormData({ ...formData, password: event.target.value })}
                    />
                    <br />
                    <button type="submit" className="sign-button">Submit</button>
                </form>

                <button onClick={ handleForgotPassword } className="minor-button">Forgot Password</button>
                <br />
                <button onClick={ () => setSignInWithEmailInitiated(false) } className="minor-button">Cancel</button>
                </>
            )}
        </div>
        </div>
    );
}

export default SignInPage;

