// SignUpPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";
import { Buffer } from 'buffer';
import Web3 from "web3";
import NavBar from '../components/NavBar';
import './../signinup.css';

const DEBUG = false;
const APP_SIGN_MESSAGE = 'Public Fund sign with Ethereum message to sign';

const firebaseConfig = {
  apiKey: "AIzaSyBePgEZpMWBogiXpNt4fEqlguaur8Cp10A",
  authDomain: "publicfund-e2c6b.firebaseapp.com",
  projectId: "publicfund-e2c6b",
  storageBucket: "publicfund-e2c6b.appspot.com",
  messagingSenderId: "400968803374",
  appId: "1:400968803374:web:bb6abef1edf29dbc4debbf",
  measurementId: "G-G83PH4DK78"
};


function SignUpPage() {

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth(app);
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [signUpMethod, setSignUpMethod] = useState('');
    const [signUpWithEmailInitiated, setSignUpWithEmailInitiated] = useState(false);
    // const [signUpWithEthereumInitiated, setSignUpWithEthereumInitiated] = useState(false);
    const [collectUserDetailsInitiated, setCollectUserDetailsInitiated] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [userDetails, setUserDetails] = useState({
        uid: '',
        firstname: '',
        lastname: '',
    });

    const handleSignUpWithEmail = async (event) => {
        event.preventDefault();

        createUserWithEmailAndPassword(auth, formData['email'], formData['password'])
            .then( (userCredential) => {
                /*
                const user = userCredential.user;
                if (user) {
                    const sessionToken = await user.getIdToken();
                    document.cookie = "public_fund_jwt=" + sessionToken + "; Secure";
                }
                 */

                // auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                /*
                auth.currentUser.getIdToken().then(function(idToken) {
                    document.cookie = "public_fund_jwt=" + idToken + "; Secure";
                }).catch(function(error) {
                    console.log('Error retrieving user ID token');
                });
                 */

                setUserDetails({ ...userDetails, uid: userCredential.user.uid });
                setSignUpMethod('email');
                setCollectUserDetailsInitiated(true);
            })
            .catch((error) => {
                setErrorMessage(`Error occurred during sign up`);

                // Clear form
                setFormData({ ...formData, email: '', password: '' });
            });
    };


    const handleSignUpWithEthereum = async () => {
        const msg = `0x${Buffer.from(APP_SIGN_MESSAGE, 'utf8').toString('hex')}`;
        let accounts = [];
        let signature = '';

        // Locally hosted Ethereum provider
        if (DEBUG) {
            window.web3 = new Web3("http://127.0.0.1:8545");
            accounts = await window.web3.eth.getAccounts();
            window.defaultAccount = accounts?.[0];

            try {
                signature = await window.web3.eth.sign(msg, window.defaultAccount);
            } catch (err) {
                setErrorMessage(`Error while signing ethereum request: ${err.message}`);
            }
        }
        // Browser extension Ethereum provider
        else if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.defaultAccount = accounts?.[0];

            try {
                signature = await window.ethereum.request({
                    method: 'personal_sign',
                    params: [msg, window.defaultAccount],
                });
            } catch (err) {
                setErrorMessage(`Error while signing ethereum request: ${err.message}`);
            }
        }
        // No Ethereum provider detected
        else {
            // @TODO: handle case where no wallets are found
            setErrorMessage('Failed to connect to Ethereum wallet. Install a wallet in your browser to sign up with Ethereum.');
            return;
        }

        console.log(`Connected to ethereum with accounts: ${accounts}`);

        // Send signed message to server and retreive JWT
        try {
            const requestBody = JSON.stringify({ signature: signature });
            const response = await fetch('/signup-with-ethereum', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: requestBody
                });

            if (response.status == 200) {
                console.log('Successful sign in with Ethereum');

                const sessionCookie = await response.json();
                // document.cookie = "public_fund_jwt=" + sessionCookie.sessionToken + "; Secure";
                signInWithCustomToken(auth, sessionCookie.sessionToken).then((userCredential) => {
                    setUserDetails({ ...userDetails, uid: window.defaultAccount });
                    setSignUpMethod('ethereum');
                    setCollectUserDetailsInitiated(true);
                })
                .catch((error) => {
                    setErrorMessage(`Error: ct sign up failed`);
                });
            }
            else {
                setErrorMessage(`Error: account creation failed`);
            }
        } catch (error) {
            setErrorMessage(`Error occurred while sending signature to server`);
        }
    };


    // Get JWT associated with user's firebase session
    const handleAuthFB = async (user) => {
        // JWT is already set for ethereum sign ups
        if (signUpMethod == "ethereum") {
            navigate('/console');
            return;
        }

        const sessionToken = await user.getIdToken();
        const requestBody = JSON.stringify({ fbatoken: sessionToken });

        const response = await fetch('/authfb', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            });

        if (response.status == 200) {
            const sessionCookie = await response.json();
            document.cookie = "public_fund_jwt=" + sessionCookie.sessionToken + "; Secure";
            navigate('/console');
        }
        else {
            setErrorMessage(`Error occurred while authorizing login`);
            setFormData({ ...formData, email: '', password: '' });
        }
    };


    const handleSubmitUserDetails = async (event) => {
        event.preventDefault();

        // Set Firebase auth token when user signs up with email (JWT is already set in browser cookie for ethereum sign up)
        let sessionToken = '';
        const user = auth.currentUser;
        if (user) {
            sessionToken = await user.getIdToken();
        }

        // let sessionJWT = '';
        // const cookieTokens = document.cookie.match("(^|;)\\s*" + "public_fund_jwt" + "\\s*=\\s*([^;]+)");
        // if (cookieTokens) sessionJWT = cookieTokens.pop();

        const requestBody = JSON.stringify({ userIdToken: sessionToken, signUpMethod: signUpMethod, email: formData['email'], firstname: userDetails['firstname'], lastname: userDetails['lastname'] });

        fetch('/new-user', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: requestBody
            })
            .then(response => {})
            .then((data) => {
                // handleAuthFB(user);
                navigate('/console');
            })
            .catch( setErrorMessage(`Error occurred while saving user details`) );
    };


    // @TODO: collect email for user details only iff user did not sign up with email
    return (
        <div className="outer-sign-container">
        <NavBar />
        <br />

        <div className="sign-container">

            <h1>Public Fund</h1>
            <h2>Sign Up</h2>

            { !signUpWithEmailInitiated && !collectUserDetailsInitiated && (
                <>
                <button onClick={ () => setSignUpWithEmailInitiated(true) } className="sign-button">Sign Up with Email</button>
                <button onClick={ handleSignUpWithEthereum } className="sign-button">Sign Up with Wallet</button>
                </>
            )}

            { signUpWithEmailInitiated && !collectUserDetailsInitiated && (
                <form onSubmit={ handleSignUpWithEmail }>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={(event) => setFormData({ ...formData, password: event.target.value })}
                    />
                    <button type="submit" className="sign-button">Submit</button>
                    <br />
                    <button onClick={ () => setSignUpWithEmailInitiated(false) } className="minor-button">Cancel</button>
                </form>
            )}

            { collectUserDetailsInitiated && (
                <div>
                    <form onSubmit={ handleSubmitUserDetails }>
                        <input
                            type="text"
                            name="firstname"
                            placeholder="First name"
                            className="sign-input"
                            value={userDetails.firstname}
                            onChange={(event) => setUserDetails({ ...userDetails, firstname: event.target.value })}
                        />
                        <input
                            type="text"
                            name="lastname"
                            placeholder="Last name"
                            className="sign-input"
                            value={userDetails.lastname}
                            onChange={(event) => setUserDetails({ ...userDetails, lastname: event.target.value })}
                        />
                        <button type="submit" className="sign-button">Submit</button>
                    </form>
                </div>
            )}

            { errorMessage && <h4 className="error">{ errorMessage }</h4> }
        </div>
        </div>
    );
}

export default SignUpPage;

